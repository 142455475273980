import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { dFlex, displayOnDesktop, flexBetweenCenter, } from 'themes/commonStyles';
import dynamic from 'next/dynamic';

const Logo = dynamic(() => import("./Logo"));
const OptionsTab = dynamic(() => import("./OptionsTab"));
const MobileSearch = dynamic(() => import("./MobileSearch"));
const ProfileSettings = dynamic(() => import("./ProfileSettings"));

const Header = () => {
  return (
    <>
      <Box sx={{ boxShadow: "0 3px 10px rgb(0 0 0 / 10%)" }}>
        <Box
          sx={{
            ...dFlex,
            minHeight: 70,
              paddingTop: 1
          }}
        >
          <Container maxWidth="xl">
            <Box
              sx={{
                ...flexBetweenCenter,
                minHeight: 70,
                px: 4,
              }}
            >
              <Box sx={displayOnDesktop}>
                <Logo />
              </Box>
              <Box sx={displayOnDesktop}>
                <MobileSearch />
              </Box>
              <Box sx={displayOnDesktop}>
                <ProfileSettings />
              </Box>

              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <MobileSearch />
              </Box>
            </Box>
          </Container>
        </Box>
        <OptionsTab />
      </Box>
    </>
  );
};

export default Header;
